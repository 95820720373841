export enum AlertType {
  Default = 0,
  Warning = 1,
  Success = 2,
  Error = 3,
  Clipboard = 4,
  Progress = 5
}

export enum AlertCustom {
  Default,
  Progress
}

export interface AlertObj {
  default: AlertCall
  warning: AlertCall
  success: AlertCall
  error: AlertCall
  clipboard: AlertCall
  progress: AlertCall<ProgressAlert>
  arr: Alert[]
}

export interface AlertOptions {
  icon: string
  color: string
}

export type AlertCall<T = Alert> = (text: string, params?: Record<string, string>, timeout?: number) => T

export type AnyAlert = Alert | ProgressAlert

export interface Alert {
  text: string
  params: Record<string, string>
  amount?: number // amount of same alerts
  id: number
  timeout: number
  date: number
  active: boolean
  canClose: boolean
  infinite?: boolean
  options: AlertOptions
  type: AlertType
  custom?: AlertCustom

  init: () => void
}

export interface ProgressAlert extends Alert {
  progress: number
  progressMax: number
  currentMessage: string
}

import '@p/app/service/stateInit'

import { app } from './service/vue.ts'
import UIcon from '@p/form/components/UIcon.vue'
import ganttastic from '@infectoone/vue-ganttastic'

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
app.component('UIcon', UIcon)
app.use(ganttastic)
app.mount('#app')

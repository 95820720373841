/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type { CoreApi, CoreAuthBase, InitOverrideFunction, ResponseError } from '@pitman/core-api'
import { Configuration } from '@pitman/core-api'

import type { AdApi } from '@pitman/ad-api'
import type { MetaApi } from '@pitman/meta-api'
import type {
  DatacenterApi, IsoApi, NetworkGroupApi,
  FolderApi, MachineApi, PoolMasterApi, SnapshotApi, StatusApi, TenantApi, NetworkWrapperApi, DatastoreGroupApi, ResourcePoolGroupApi, ResourcePoolGroupQuotaApi, DatastoreGroupQuotaApi, ResourcePoolApi, DatastoreApi, BackupApi
} from '@pitman/vm-api'
import type { LangApi } from '@pitman/lang-api'
import type { PbxApi } from '@pitman/pbx-api'
import type { InspectorApi } from '@pitman/inspector-api'
import type { DocsApi } from '@pitman/docs-api'
import type { DirectoryApi } from '@pitman/directory-api'
import type { BillingApi } from '@pitman/billing-api'
import type { LicenseApi } from '@pitman/license-api'
import type { WikiApi } from '@pitman/wiki-api'
import type { AzureApi } from '@pitman/azure-api'
import type { FooterApi } from '@pitman/footer-api'

import { EventOn } from '@p/app/helper/event.ts'
import { type Getter } from '@p/app/types/Getter.ts'
import { errorAlert } from '@p/app/helper/error.ts'
import { EventEmitter } from 'eventemitter3'
import { AuthenticationType, type Authentication } from '@p/app/types/State.ts'
import log from '../logging.ts'
import { state } from '../state.ts'
import { v4 as uuidv4 } from 'uuid'
import { tasksHere } from '../tasks.ts'
import openId from '@p/app/helper/openId.ts'
import { ErrorResponse } from 'oidc-client-ts'
import { updateUrl } from '../ws.ts'

export const basePath = window.document.baseURI.slice(0, -1)

const refreshCodes = [
  7, // TokenExpired
  8, // TokenMissing
  9 // TokenInvalid
]

export const authentication: Authentication = {
  type: AuthenticationType.Legacy,
  access: null,
  refresh: null,
  userId: null
}

export const configuration = new Configuration({
  basePath
}) as any

export const core = async (): Promise<CoreApi> => new (await import('@pitman/core-api')).CoreApi(configuration)
export const directory = async (): Promise<DirectoryApi> => new (await import('@pitman/directory-api')).DirectoryApi(configuration)
export const ad = async (): Promise<AdApi> => new (await import('@pitman/ad-api')).AdApi(configuration)
export const meta = async (): Promise<MetaApi> => new (await import('@pitman/meta-api')).MetaApi(configuration)
export const lang = async (): Promise<LangApi> => new (await import('@pitman/lang-api')).LangApi(configuration)
export const pbx = async (): Promise<PbxApi> => new (await import('@pitman/pbx-api')).PbxApi(configuration)
export const inspector = async (): Promise<InspectorApi> => new (await import('@pitman/inspector-api')).InspectorApi(configuration)
export const wiki = async (): Promise<WikiApi> => new (await import('@pitman/wiki-api')).WikiApi(configuration)
export const azure = async (): Promise<AzureApi> => new (await import('@pitman/azure-api')).AzureApi(configuration)
export const footer = async (): Promise<FooterApi> => new (await import('@pitman/footer-api')).FooterApi(configuration)

export const vmFolder = async (): Promise<FolderApi> => new (await import('@pitman/vm-api')).FolderApi(configuration)
export const vmMachine = async (): Promise<MachineApi> => new (await import('@pitman/vm-api')).MachineApi(configuration)
export const vmPool = async (): Promise<PoolMasterApi> => new (await import('@pitman/vm-api')).PoolMasterApi(configuration)
export const vmSnapshot = async (): Promise<SnapshotApi> => new (await import('@pitman/vm-api')).SnapshotApi(configuration)
export const vmStatus = async (): Promise<StatusApi> => new (await import('@pitman/vm-api')).StatusApi(configuration)
export const vmTenant = async (): Promise<TenantApi> => new (await import('@pitman/vm-api')).TenantApi(configuration)

export const vmDatastore = async (): Promise<DatastoreApi> => new (await import('@pitman/vm-api')).DatastoreApi(configuration)
export const vmDatastoreGroup = async (): Promise<DatastoreGroupApi> => new (await import('@pitman/vm-api')).DatastoreGroupApi(configuration)
export const vmDatastoreGroupQuota = async (): Promise<DatastoreGroupQuotaApi> => new (await import('@pitman/vm-api')).DatastoreGroupQuotaApi(configuration)

export const vmResourcePool = async (): Promise<ResourcePoolApi> => new (await import('@pitman/vm-api')).ResourcePoolApi(configuration)
export const vmResourcePoolGroup = async (): Promise<ResourcePoolGroupApi> => new (await import('@pitman/vm-api')).ResourcePoolGroupApi(configuration)
export const vmResourcePoolGroupQuota = async (): Promise<ResourcePoolGroupQuotaApi> => new (await import('@pitman/vm-api')).ResourcePoolGroupQuotaApi(configuration)

export const vmNetworkGroup = async (): Promise<NetworkGroupApi> => new (await import('@pitman/vm-api')).NetworkGroupApi(configuration)
export const vmNetworkWrapper = async (): Promise<NetworkWrapperApi> => new (await import('@pitman/vm-api')).NetworkWrapperApi(configuration)

export const vmDatacenter = async (): Promise<DatacenterApi> => new (await import('@pitman/vm-api')).DatacenterApi(configuration)
export const vmIso = async (): Promise<IsoApi> => new (await import('@pitman/vm-api')).IsoApi(configuration)
export const vmBackup = async (): Promise<BackupApi> => new (await import('@pitman/vm-api')).BackupApi(configuration)

export const docs = async (): Promise<DocsApi> => new (await import('@pitman/docs-api')).DocsApi(configuration)
export const billing = async (): Promise<BillingApi> => new (await import('@pitman/billing-api')).BillingApi(configuration)
export const license = async (): Promise<LicenseApi> => new (await import('@pitman/license-api')).LicenseApi(configuration)

const refreshError = new EventOn<void>()

export let activeRequests = 0

export const activeRequestEe = new EventEmitter()

function addActiveRequest (): void {
  activeRequests++
  activeRequestEe.emit('update', activeRequests)
}

function removeActiveRequest (): void {
  activeRequests--
  activeRequestEe.emit('update', activeRequests)
}

type Head<T extends any[]> = RemoveOpt<T> extends [ ...infer Head, any ] ? Head : never

// https://github.com/microsoft/TypeScript/issues/31810
type AddUndefined<T, OriginalT> = (
  {
    [index in keyof T]: (
      undefined extends OriginalT[Extract<index, keyof OriginalT>] ?
        undefined | T[index] :
        T[index]
    )
  }
)
type RemoveOpt<T> = (
  AddUndefined<
  {
    [index in keyof T]-?: T[index]
  },
  T
  >
)

 type KeysMatching<T extends object, V> = {
   [K in keyof T]-?: T[K] extends V ? K extends string ? K : never : never
 }[keyof T]

 type FetchApiKeys<T extends object> = KeysMatching<T, FetchBase>

 type FetchBase = (...args: [...params: any, initOverrides?: RequestInit]) => Promise<any>

 type FetchApi<T extends Record<string, any>> = { [key in FetchApiKeys<T>]: T[key] extends FetchBase ? T[key] : never }

 type Result<API extends Record<string, any>, METHOD extends keyof FetchApi<API>> = Awaited<ReturnType<FetchApi<API>[METHOD]>>

export function updateCredentials (auth: CoreAuthBase): void {
  authentication.access = auth.access ?? null
  authentication.refresh = auth.refresh ?? null
  authentication.userId = auth.userId?.toString() ?? null
  if (authentication.type !== AuthenticationType.Legacy) updateUrl(authentication)
}

export function isApiError (e: any): e is ResponseError {
  return e?.response?.status !== undefined && e?.response?.text !== undefined
}

export function apiCancelable<
  API extends Record<string, any>,
  METHOD extends keyof FetchApi<API>,
> (
  api: () => Promise<API>,
  method: METHOD,
  ...params: Head<Parameters<FetchApi<API>[METHOD]>>
): Getter<Result<FetchApi<API>, METHOD>> {
  const controller = new AbortController()

  async function call (): Promise<Result<API, METHOD> | undefined> {
    addActiveRequest()
    const a = await api()
    const func = (a[method] as any).bind(a) as FetchBase
    try {
      return await func(...params, initOverridesSignal.bind(undefined, controller.signal))
    } catch (e: any) {
      try {
        if (controller.signal.aborted) return undefined
        if (!isApiError(e)) throw e
        const error = await e.response.json()
        if (!state.authenticated) throw error
        await errorRefresh(error)
        return await func(...params, initOverridesSignal.bind(undefined, controller.signal))
      } catch (e: any) {
        if (controller.signal.aborted) return undefined
        if (!isApiError(e)) throw e
        throw await e.response.json()
      }
    } finally {
      removeActiveRequest()
    }
  }

  return {
    data: call(),
    cancel: () => { controller.abort() }
  }
}

export async function apiAbortable<
  API extends Record<string, any>,
  METHOD extends keyof FetchApi<API>,
> (
  signal: AbortSignal | undefined,
  api: () => Promise<API>,
  method: METHOD,
  ...params: Head<Parameters<FetchApi<API>[METHOD]>>
): Promise<Result<FetchApi<API>, METHOD>> {
  addActiveRequest()
  const a = await api()
  const func = (a[method] as any).bind(a) as FetchBase
  try {
    return await func(...params, initOverridesSignal.bind(undefined, signal))
  } catch (e: any) {
    throwIfAbortError(e)
    try {
      if (!isApiError(e)) throw e
      const error = await e.response.json()
      if (!state.authenticated) throw error
      await errorRefresh(error)
      return await func(...params, initOverridesSignal.bind(undefined, signal))
    } catch (e: any) {
      throwIfAbortError(e)
      if (!isApiError(e)) throw e
      throw await e.response.json()
    }
  } finally {
    removeActiveRequest()
  }
}

function throwIfAbortError (e: any): void {
  if (e instanceof DOMException && e?.name === 'AbortError') throw e
  if (e?.cause instanceof DOMException && e?.cause?.name === 'AbortError') throw e.cause
}

export interface ApiConfig<T> {
  alert: boolean
  task: boolean | ((result: T) => number)
}

function tasksHereProcess (res: number | number[]): void {
  if (!Array.isArray(res)) res = [res]
  for (const id of res) {
    if (typeof id !== 'number') continue
    tasksHere.add(id)
  }
}

export async function api<
  API extends Record<string, any>,
  METHOD extends keyof FetchApi<API>,
> (
  config: ApiConfig<Result<FetchApi<API>, METHOD>>,
  api: () => Promise<API>,
  method: METHOD,
  ...params: Head<Parameters<FetchApi<API>[METHOD]>>
): Promise<Result<FetchApi<API>, METHOD>> {
  addActiveRequest()
  const a = await api()
  const func = (a[method] as any).bind(a) as FetchBase
  try {
    const result = await func(...params, initOverrides)
    if (config.task) tasksHereProcess(typeof config.task === 'function' ? config.task(result) : result)
    return result
  } catch (e: any) {
    if (!isApiError(e)) throw e
    const error = await e.response.json()
    if (!state.authenticated) {
      if (config.alert) errorAlert(error)
      throw error
    }
    try {
      await errorRefresh(error)
      const result = await func(...params, initOverrides)
      if (config.task) tasksHereProcess(typeof config.task === 'function' ? config.task(result) : result)
      return result
    } catch (e: any) {
      if (config.alert) errorAlert(error)
      console.error('service alert', e)
      throw error
    }
  } finally {
    removeActiveRequest()
  }
}

async function initOverridesSignal (signal: AbortSignal | undefined, ...args: Parameters<InitOverrideFunction> | []): ReturnType<InitOverrideFunction> {
  return { ...fetchOptions(), headers: fetchHeaders(args[0]?.init.headers), signal }
}

async function initOverrides (...args: Parameters<InitOverrideFunction> | []): ReturnType<InitOverrideFunction> {
  return await initOverridesSignal(undefined, ...args)
}

export function fetchOptions (): RequestInit {
  return { referrerPolicy: 'no-referrer', mode: 'cors', credentials: authentication.type ? 'omit' : 'include' }
}

export function fetchHeaders (headers: Record<string, string> = {}): Record<string, string> {
  headers.sessionid = log.sessionId
  headers.traceid = uuidv4()

  if (log.logLevel.value !== null) headers.loglevel = log.logLevel.value.toString()
  if (authentication.access !== null) headers['x-access'] = authentication.access
  if (authentication.refresh !== null && authentication.type !== AuthenticationType.OpenID) headers['x-refresh'] = authentication.refresh ?? ''
  if (authentication.userId != null) headers['x-userid'] = authentication.userId
  return headers
}

async function errorRefresh (result: any): Promise<void> {
  if (result?.errors?.some((x: { code: number }) => refreshCodes.includes(x?.code)) !== true) throw result
  try {
    await refresh()
  } catch (e: any) {
    if (e.message !== 'Network Error') refreshError.emit()
    throw e
  }
}

let promise: Promise<any> | null | undefined = null
export async function refresh (): Promise<void> {
  if (promise !== null) {
    await promise; return
  }
  try {
    promise = authentication.type === AuthenticationType.OpenID ? openId?.signinSilent().then(async () => await openId?.signinCallback()) : coreAuthRefresh()
    await promise
    setTimeout(() => { promise = null }, 10000)
  } catch (e: any) {
    if (e instanceof ErrorResponse && e.error_description === 'Errors.User.RefreshToken.Invalid') void openId?.signinRedirect()
    else if (e?.code === 'ECONNABORTED') promise = null
    else setTimeout(() => { promise = null }, 10000)
  }
}

async function coreAuthRefresh (): Promise<void> {
  const res = await (await core()).coreAuthRefresh(initOverrides)
  if (res !== null) updateCredentials(res)
}

export const onRefreshError = refreshError.on.bind(refreshError)

import KnotCache from '@p/app/cache/Knot.ts'
import { state } from '@p/app/service/state.ts'
import type { Knot } from '@p/app/types/Knot.ts'
import type { CoreNode, CoreNodeRow } from '@pitman/core-api'
import { CorePrivileges } from '@pitman/core-api'
import type { Entity } from '@p/uicache'
import { arrayify } from './array.ts'
import { type Raw, reactive, markRaw, shallowReactive } from 'vue'
import { type KnotEntityTypeCallable } from '@p/app/components/composables/KnotType.ts'

export const knotTypesAsync = shallowReactive(new Map<number, (() => Promise<KnotEntityTypeCallable>) | Promise<KnotEntityTypeCallable>>())
export const knotTypes = shallowReactive(new Map<number, KnotEntityTypeCallable>())

const listenersNew = import.meta.glob('./../data/knot/*.ts')

Object.keys(listenersNew).forEach(e => {
  const type = parseInt(e.replace(/\.\.\/data\/knot\/([0-9]+)(-.*)?(\.(t|j)s$)/gi, '$1'))
  knotTypesAsync.set(type, async () => {
    const res: Promise<KnotEntityTypeCallable> = listenersNew[e]().then((d: any) => {
      knotTypesAsync.delete(type)
      const rd: KnotEntityTypeCallable = markRaw(d.default)
      knotTypes.set(type, rd)
      return rd
    })
    if (knotTypesAsync.has(type)) knotTypesAsync.set(type, res)
    return await res
  })
})

export function getKnotType (type: number): { id: number, type: true | false | KnotEntityTypeCallable } {
  const res = knotTypes.get(type)
  if (res === undefined) {
    const d = knotTypesAsync.get(type)
    if (d === undefined) return { id: type, type: false } // Does not exist
    if (!(d instanceof Promise)) void d()
    return { id: type, type: true }
  }
  return { id: type, type: res }
}

export async function getTenant (knot: Knot, ignoreSelf: boolean = true): Promise<Entity<CoreNode, Knot>> {
  if (!ignoreSelf && knot.types.includes(CorePrivileges.CoreTenantSelect)) {
    return KnotCache.get(knot.id) // To make a new instance you can release
  }

  const tenantRoute = await upwards(knot.id, (obj) => !obj.types.includes(CorePrivileges.CoreTenantSelect))

  const tenant = tenantRoute[tenantRoute.length - 1]
  tenant.aquire()
  tenantRoute.forEach(x => x.release())

  return tenant
}

export async function getTenantOrFolder (knot: Knot, ignoreSelf: boolean = true): Promise<Entity<CoreNode, Knot>> {
  if (knot.types.includes(CorePrivileges.CoreFolderSelect)) {
    return KnotCache.get(knot.id) // To make a new instance you can release
  }

  let i = 0
  const tenantRoute = await upwards(knot.id, (obj) => {
    if (obj.types.includes(CorePrivileges.CoreFolderSelect)) return false
    if (i++ === 0) return true
    return !obj.types.includes(CorePrivileges.CoreTenantSelect)
  })

  const tenant = tenantRoute[tenantRoute.length - 1]
  tenant.aquire()
  tenantRoute.forEach(x => x.release())

  return tenant
}

/**
 * Gets all tenants from knot to own tenant
 * @param knot from
 * @returns array of tenants
 */
export async function getTenantsToMine (knot: Knot): Promise<Array<Entity<CoreNode, Knot>>> {
  const tenantRoute = await upwards(knot.id, (obj) => {
    return !(obj.id === state.tenant)
  })

  tenantRoute.filter(x => !x.data.types.includes(CorePrivileges.CoreTenantSelect)).forEach(x => x.release())

  return tenantRoute.filter(x => x.data.types.includes(CorePrivileges.CoreTenantSelect))
}

export async function getOwnUser (): Promise<Entity<CoreNode, Knot>> {
  const user = KnotCache.get(state.id)
  return user
}

export async function upwardsTo (from: number, to: number): Promise<Array<Entity<CoreNode, Knot>>> {
  return await upwards(from, (object: CoreNode) => object.id !== to)
}

export async function upwards (from: number, to: (object: CoreNode) => boolean): Promise<Array<Entity<CoreNode, Knot>>> {
  const objects: Array<Entity<CoreNode, Knot>> = []
  objects.push(await KnotCache.getAsync(from))
  let cur = objects[0]
  while (cur.error === null && cur.data.parent !== 0 && to(cur.data)) {
    objects.push(await KnotCache.getAsync(cur.data.parent))
    cur = objects[objects.length - 1]
  }
  return objects
}

export function getRealTenantId (knot: { types: number[], id: number, tenant: number }): number {
  return knot.types[0] === CorePrivileges.CoreTenantSelect ? knot.id : knot.tenant
}

export async function getRealTenantIdByNodeId (nodeId: number): Promise<number> {
  const at = await KnotCache.getData(nodeId)
  return at.types[0] === CorePrivileges.CoreTenantSelect ? at.id : at.tenant
}

function splitPath (path: string): number[] {
  return path.split('.').map(x => parseInt(x))
}

/**
 * Checks, if the knot is in the path
 */
export function isSubPath (path: string, node: number, includeSelf = false): boolean {
  const pathArr = splitPath(path)

  return pathArr.includes(node) || (includeSelf && isCurrentPath(path, node))
}

/**
 * Checks, if a string path IS the node id
 */
export function isCurrentPath (path: string, node: number): boolean {
  const pathArr = splitPath(path)

  return pathArr.reverse()[0] === node
}

export function getParentPath (path: string): string {
  const pathArr = splitPath(path)

  if (pathArr.length < 2) return '0'

  return pathArr.slice(0, pathArr.length - 1).join('.')
}

export function getParentPathId (path: string): number {
  const pathArr = getParentPath(path).split('.')

  if (pathArr.length < 1) return 0
  return parseInt(pathArr.reverse()[0])
}

export async function getPathOfId (id: number): Promise<string> {
  return (await KnotCache.getData(id)).path
}

export class KnotCollector {
  knots: Map<number, Raw<Entity<CoreNode, Knot>>> = reactive(new Map())

  get (id: number): CoreNodeRow {
    if (!this.knots.has(id)) this.add(id)
    return this.knots.get(id)?.data ?? KnotCache.default()
  }

  add (id: number | number[]): void {
    const ids = arrayify(id).filter(x => !this.knots.has(x))
    if (ids.length === 0) return
    ids.forEach(x => this.knots.set(x, KnotCache.get(x)))
  }

  destroy (): void {
    [...this.knots.values()].forEach(e => e.release())
    this.knots.clear()
  }
}

import { api, docs } from '@p/app/service/backend/index.ts'
import type { DocsDoc } from '@pitman/docs-api'

export async function overview (): Promise<DocsDoc[]> {
  return await api({ alert: true, task: false }, docs, 'docsOverview')
}

export async function tenantOverview (tenantId: number): Promise<DocsDoc[]> {
  return await api({ alert: true, task: false }, docs, 'docsTenantOverview', tenantId)
}

export async function docsTenantDocumentRegenerate (tenantId: number, document: number): Promise<string> {
  return await api({ alert: true, task: false }, docs, 'docsTenantDocumentRegenerate', tenantId, document)
}

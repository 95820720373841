import ReconnectingWeb from 'reconnecting-websocket'
import { EventEmitter } from 'eventemitter3'
import { EventOn } from '@p/app/helper/event.ts'
import { type Authentication, AuthenticationType } from '@p/app/types/State.ts'
import log, { ui } from '@p/app/service/logging.ts'
import { reactive } from 'vue'

const baseUrl = window.document.baseURI.replace('http', 'ws') + 'v1/ws'

const socket = new ReconnectingWeb(getUrl(), undefined, { startClosed: true, reconnectionDelayGrowFactor: 1 })

const ee = new EventEmitter()

const connect = new EventOn<'open' | 'close' | 'error'>()

export const status = reactive({
  connected: false,
  enabled: false,
  error: false,
  reloading: false,
  restarting: false
})

socket.onopen = () => {
  if (status.error) status.error = false
  if (status.connected) return
  status.connected = true
  status.restarting = false
  connect.emit('open')
}

// 1012 => server restarting
socket.onclose = (event) => {
  if (!status.connected) return
  if (!status.error && event.code !== 1012) status.error = true
  else if (event.code === 1012) status.restarting = true
  status.connected = false
  connect.emit('close')
}

socket.onerror = (event) => {
  if (!status.error) status.error = true
  console.error(event)
  connect.emit('error')
}

socket.onmessage = function (event) {
  try {
    const parse = JSON.parse(event.data as string)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    ee.emit(parse[0], ...parse.slice(1))
  } catch (e) {
    console.error(e)
  }
}
export function send (event: string, ...args: any[]): void {
  socket.send(JSON.stringify([event, ...args]))
}

export function on (event: string, fn: (...args: any[]) => void, context?: any): void {
  ee.on(event, fn, context)
}

export function once (event: string, fn: (...args: any[]) => void, context?: any): void {
  ee.once(event, fn, context)
}

export function off (event: string, fn?: ((...args: any[]) => void) | undefined, context?: any, once?: boolean | undefined): void {
  ee.off(event, fn, context, once)
}

export function stop (): void {
  if (!status.enabled) return
  if (status.error) status.error = false
  status.enabled = false
  socket.close()
}

export function start (): void {
  if (status.enabled) return
  if (status.error) status.error = false
  status.enabled = true
  socket.reconnect()
}

window.addEventListener('beforeunload', function (event) {
  status.reloading = true
  socket.close()
})

export const onConnectionChange = connect.on.bind(connect)

function getUrl (authentication?: Authentication): string {
  const params = new URLSearchParams()

  // set access token
  if (authentication?.type != null && authentication?.access != null && [AuthenticationType.Plain, AuthenticationType.OpenID].includes(authentication?.type)) {
    params.append('access', encodeURIComponent(authentication.access))
  }

  // set userId
  if (authentication?.type != null && authentication?.userId != null && [AuthenticationType.Plain, AuthenticationType.OpenID].includes(authentication?.type)) {
    params.append('userid', encodeURIComponent(authentication.userId))
  }

  // set log level
  if (log.logLevel.value !== null) {
    params.append('loglevel', encodeURIComponent(log.logLevel.value))
  }

  if (ui !== null) {
    params.append('ui', ui)
  }
  return baseUrl + '?' + params.toString()
}

export function updateUrl (authentication: Authentication): void {
  // @ts-expect-error workaround
  socket._url = getUrl(authentication)
}

import type { CoreNodeRow, DataTableInformation, MetadataQuery } from '@pitman/core-api'
import { type Getter } from '@p/app/types/Getter.ts'
import { api, apiAbortable, apiCancelable, core } from '../index.ts'

/**
 * @deprecated
 */
export function nodeChildrenCancelable (id: number, countChildren: boolean = false): Getter<CoreNodeRow[]> {
  return apiCancelable(core, 'coreNodeChildren', id, countChildren)
}

export async function nodeChildren (signal: AbortSignal, id: number, countChildren: boolean = false): Promise<CoreNodeRow[]> {
  return await apiAbortable(signal, core, 'coreNodeChildren', id, countChildren)
}

export function nodeFilter (tenantId: number, filterType: number, deepTenant: boolean, ids: number[]): Getter<boolean[]> {
  return apiCancelable(core, 'coreNodeFilter', tenantId, filterType, deepTenant, ids)
}

/**
 * @deprecated
 */
export function nodeGetCancelable (id: number, countChildren: boolean = false): Getter<CoreNodeRow> {
  return apiCancelable(core, 'coreNodeGet', id, countChildren)
}

export async function nodeGet (signal: AbortSignal, id: number, countChildren: boolean = false): Promise<CoreNodeRow> {
  return await apiAbortable(signal, core, 'coreNodeGet', id, countChildren)
}

export function nodeGets (ids: number[], countChildren: boolean = false): Getter<CoreNodeRow[]> {
  return apiCancelable(core, 'coreNodeGets', ids, countChildren)
}

export function coreNodeDependencies (nodeId: number, dependencies: number[]): Getter<Array<{ node: number, depends: number[], dependsTenant: number[] }>> {
  return apiCancelable(core, 'coreNodeDependencies', nodeId, dependencies)
}

export async function getTableInformations (query: MetadataQuery): Promise<DataTableInformation[]> {
  return await api({ alert: true, task: false }, core, 'coreNodeGetTableInformations', query)
}

// Sind in den Elementen 1,2 Mandanten enthalten
// Antwort: [ja, nein]
// filter(5, CorePrivileges.CoreTenantSelect, [1, 2])

import { login as authLogin, token as authToken, startup as authStartup } from '@p/app/service/backend/core/auth.ts'
import type { CoreAuth, CoreAuthTokenRequest } from '@pitman/core-api'
import { EventOn } from '@p/app/helper/event.ts'
import rbac from './rbac.ts'
import { updateUrl } from './ws.ts'
import { authentication } from './backend/index.ts'
import { state } from './state.ts'
import ChildrenCache from '@p/app/cache/Children.ts'
import KnotCache from '@p/app/cache/Knot.ts'
import { AuthenticationType } from '../types/State.ts'

const loginE = new EventOn<void>()
export const tenantEvent = new EventOn<number>()

export async function login (username: string, password: string, permanent: boolean, totp?: string): Promise<void> {
  auth(await authLogin({ username, password, permanent, totp }, authentication.type === AuthenticationType.Plain))
}

export async function token (token: CoreAuthTokenRequest): Promise<void> {
  auth(await authToken(token, authentication.type === AuthenticationType.Plain))
}

export async function startup (): Promise<void> {
  auth(await authStartup())
}

export function auth (auth: CoreAuth): void {
  if (authentication.type === AuthenticationType.Plain) {
    authentication.access = auth.access ?? null
    authentication.refresh = auth.refresh ?? null
    authentication.userId = auth.userId == null ? null : auth.userId.toString()
  }
  if (authentication.type !== AuthenticationType.Legacy) updateUrl(authentication)
  state.id = auth.userId
  state.tenant = auth.tenantId
  state.authenticated = true
  state.impersonated = auth.impersonated
  state.startView = auth.startView
  rbac.reload(auth.permissions)
  KnotCache.invalidate()
  ChildrenCache.invalidate()
  loginE.emit()
  tenantEvent.emit(state.tenant)
}
export const onLogin = loginE.on.bind(loginE)

import { core, api } from '@p/app/service/backend/index.ts'
import type { CopySequence, CoreNode, ProcessableNode, ProcessableNodeOperation, ProcessingReport } from '@pitman/core-api'

export const defaults: CoreNode = {
  id: 0,
  path: '',
  tenant: 0,
  parent: 0,
  name: '',
  types: [],
  privileges: [],
  functionTags: null
}

export async function edit (objectId: number, parentId: number): Promise<number> {
  return await api({ alert: true, task: true }, core, 'coreNodeEdit', objectId, parentId)
}

export async function deleteNode (nodeData: ProcessableNode[]): Promise<number[]> {
  return await api({ alert: true, task: true }, core, 'coreNodeRemove', nodeData)
}

export async function checkRemove (nodeId: number, type: number): Promise<ProcessingReport> {
  return await api({ alert: true, task: false }, core, 'coreNodeCheckRemove', nodeId, type)
}

export async function checkModuleRemove (tenant: number, types: number[]): Promise<ProcessingReport> {
  return await api({ alert: true, task: false }, core, 'coreNodeCheckModuleRemove', tenant, types)
}

export async function checkCopy (nodeId: number, type: number): Promise<ProcessingReport> {
  return await api({ alert: true, task: false }, core, 'coreNodeCheckCopy', nodeId, type)
}

export async function copyNode (nodeId: number, copySequence: CopySequence[]): Promise<number> {
  return await api({ alert: true, task: true }, core, 'coreNodeCopy', nodeId, copySequence)
}

export async function mergeNode (nodeId: number, mergeSequence: ProcessableNodeOperation[]): Promise<number> {
  return await api({ alert: true, task: true }, core, 'coreNodeMerge', nodeId, mergeSequence)
}

import { type Raw, markRaw, shallowReactive } from 'vue'

import type { EntityAdapter, HttpError } from '../types/helper.ts'

export class Entity<T, U = T> {
  readonly #t: EntityAdapter<T, U>

  #abort: AbortController | null = null
  #curl: Promise<T> | null = null
  #refs = 0

  readonly #self: {
    data: U
    error: HttpError | null
    loading: boolean
    destroyed: boolean
  }

  id!: number
  reason: number[] = []

  get loading (): boolean {
    return this.#self.loading
  }

  get destroyed (): boolean {
    return this.#self.destroyed
  }

  private constructor (
    t: EntityAdapter<T, U>,
    id: number,
    data?: T
  ) {
    this.#t = t
    this.id = id

    this.#self = Object.assign(shallowReactive({}), {
      data: this.#process(data ?? this.#t.default),
      error: null,
      loading: false,
      destroyed: false
    })

    if (data !== undefined || id === 0) return

    this.#retrieve()
    return this
  }

  /**
   * https://github.com/vuejs/core/issues/2981
   */
  static factory<T, U = T> (
    t: EntityAdapter<T, U>,
    id: number,
    data?: T
  ): Raw<Entity<T, U>> {
    return markRaw(new Entity(t, id, data))
  }

  get error (): HttpError | null {
    return this.#self.error
  }

  get data (): U {
    return this.#self.data
  }

  set data (data: U) {
    this.#self.data = data
  }

  map (data: T, reason: number[] = []): this {
    if (this.destroyed) return this
    this.reason = reason
    this.#abort?.abort()
    this.#self.data = this.#process(data)
    this.#self.error = null
    this.#retrieveFinish()
    return this
  }

  invalidate (reason?: number): this {
    if (this.destroyed) return this
    if (this.id <= 0) return this
    this.#retrieve(reason)
    return this
  }

  async loaded (): Promise<this> {
    do {
      try {
        await this.#curl
      } catch (e) { }
    } while (this.loading)
    return this
  }

  release (): this {
    if (this.destroyed) return this
    this.#refs--
    if (this.#refs <= 0) this.#destroy()
    return this
  }

  aquire (): this {
    if (this.destroyed) return this
    this.#refs++
    return this
  }

  #destroy (): void {
    if (this.#self.destroyed) return
    this.#t?.destroy?.(this.id, this.#self.data)
    this.#self.destroyed = true
  }

  #retrieve (reason?: number): void {
    if (!this.#self.loading) {
      this.#self.loading = true
      this.reason = []
    }

    if (reason !== undefined) this.reason.push(reason)

    this.#abort?.abort()
    this.#abort = new AbortController()
    this.#curl = this.#t.retrieve(this.id, this.#abort.signal)

    this.#curl.then((data) => {
      this.#self.data = this.#process(data)
      this.#self.error = null
      this.#retrieveFinish()
    }, (e) => {
      if (e instanceof DOMException && e?.name === 'AbortError') return
      this.#self.data = this.#process(this.#t.default)
      if (typeof e?.status !== 'number') {
        console.warn('error object not expected', e)
        this.#self.error = {
          errors: [],
          status: 500
        }
      } else this.#self.error = e
      this.#retrieveFinish()
    })
  }

  #retrieveFinish (): void {
    this.#self.loading = false
    this.#abort = null
    this.#curl = null
  }

  #process (data: T): U {
    return this.#t?.process === undefined ? data as any as U : this.#t.process(this.id, data, this.#self?.data, this.reason)
  }
}

import { inject, ref, type InjectionKey, type Ref, provide } from 'vue'

export const uKnotTemp: InjectionKey<Record<string, any>> = Symbol('KnotTemp')

export function useKnotTemp<T> (name: string, defaultValue?: T): Ref<T> {
  const knotTemp = inject(uKnotTemp)
  if (!knotTemp) {
    throw new Error('useKnotTemp must be called within a KnotTempProvider')
  }

  if (!knotTemp[name]) {
    knotTemp[name] = ref(defaultValue)
  }

  return knotTemp[name] as Ref<T>
}

export function useKnotTempProvider (): { clear: () => void } {
  const knotTemp = ({})

  provide(uKnotTemp, knotTemp)

  function clear (): void {
    for (const key in knotTemp) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete knotTemp[key]
    }
  }

  return {
    clear
  }
}

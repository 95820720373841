import { apiAbortable, core } from '@p/app/service/backend/index.ts'
import type { Knot } from '@p/app/types/Knot.ts'
import type { CoreNode, CoreNodeRow } from '@pitman/core-api'
import { EntityLink, EntityStore, retrieveCollector } from '@p/uicache'
import clone from 'clone-deep'
import { markRaw } from 'vue'
import { getRealTenantId } from '../helper/knot.ts'

import { remoteConfig } from '@p/app/service/remoteConfig.ts'

const retrieve = retrieveCollector<CoreNodeRow, number>(async (ids, signal) => {
  const nodes = await apiAbortable(signal, core, 'coreNodeGets', ids, remoteConfig.countChildrenDisable !== true)
  return nodes.map(e => [e.id, e])
})

//   retrieve: async (id: number, signal: AbortSignal) => await apiAbortable(signal, core, 'coreNodeGet', id),

export const KnotLink = markRaw(new EntityLink<CoreNode, Knot>({
  default: {
    parent: 0,
    id: 0,
    tenant: 0,
    types: [],
    path: '0',
    name: '',
    functionTags: null
  },
  retrieve,
  process: (id, data) => {
    const d = data = id === 0 ? clone(data as Knot) : data as Knot
    return {
      ...d,
      realTenantId: getRealTenantId(d)
    }
  },
  linkId: (data) => data?.tenant !== undefined && data?.tenant > 0 ? data.tenant : null
}))

const KnotCache = markRaw(new EntityStore<CoreNode, Knot>(KnotLink.type()))

export default KnotCache

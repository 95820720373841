import { apiAbortable, core } from '@p/app/service/backend/index.ts'
import type { CoreNode } from '@pitman/core-api'
import type { Entity } from '@p/uicache'
import { EntityStore } from '@p/uicache'
import KnotCache from './Knot.ts'
import type { Knot } from '@p/app/types/Knot.ts'
import { markRaw } from 'vue'

import { remoteConfig } from '@p/app/service/remoteConfig.ts'

const defaultArr = []

const ChildrenCache = markRaw(new EntityStore<CoreNode[], Array<Entity<CoreNode, Knot>>>({
  default: defaultArr,
  retrieve: async (id, signal) => await apiAbortable(signal, core, 'coreNodeChildren', id as number, remoteConfig.countChildrenDisable !== true),
  process: (id, data, oldKnots, reason) => {
    if (id > 0 && KnotCache.isCached(id) && defaultArr !== data) {
      const d = KnotCache.get(id).release()
      if (d.data.children !== data.length) d.data = { ...d.data, children: data.length }
    }
    const newKnots = data.map(e => KnotCache.map(e.id, e, reason))
    oldKnots?.map((e: any) => e.release())
    return newKnots
  },
  destroy: (id, data) => {
    data?.map((e: any) => e.release())
  }
}))

/*
export const getTags = registerCache<CoreTag[]>(CoreEntityType.CoreTags, {
  default: [],
  retrieve: (id: number) => apiCancelable(core,'coreTagGetByTenant', id)
})

export const getOwnUser = async (): Promise<Entity<Node>> => {
  const user = await getCoreNode.getAsync(state.id)
  return user
}
*/

export default ChildrenCache

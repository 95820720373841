import type { Logging } from '@p/app/types/Logging.ts'
import { LogLevel } from '@p/app/types/Logging.ts'
import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const log: Logging = {
  logLevel: ref<LogLevel | null>(null),
  sessionId: uuidv4()
}

const params = new URLSearchParams(window.location.search)
const loglevel = params.get('loglevel')?.toLowerCase()
if (loglevel !== undefined) {
  switch (loglevel) {
    case 'debug':
    case '0':
      log.logLevel.value = LogLevel.Debug
      break
    case 'info':
    case '1':
      log.logLevel.value = LogLevel.Info
      break
    case 'warn':
    case '2':
      log.logLevel.value = LogLevel.Warn
      break
    case 'error':
    case '3':
      log.logLevel.value = LogLevel.Error
      break
  }
}

console.info('sessionId', log.sessionId, 'logLevel', log.logLevel.value)

export const ui = params.get('ui') ?? null

export default log

import equal from 'fast-deep-equal'
import { userConfigEdit } from '@p/app/service/backend/core/userConfig.ts'
import { EventOn } from '@p/app/helper/event.ts'
import { reactive } from 'vue'

export const remoteConfig: Record<string, any> = reactive({})
export let userId = 0
export let loaded = false
const changed = new EventOn<void>()

/**
 * Wait for remoteConfig to properly load first
 */
export function onInit (cb: () => void): void {
  if (loaded) {
    cb()
  } else {
    const ncb = (): void => { changed.off(ncb); cb() }
    changed.on(ncb)
  }
}

export function start (id: number): void {
  clear()
  userId = id
}

export function stop (): void {
  userId = 0
  loaded = false
  if (clear()) changed.emit()
}

function clear (): boolean {
  let ch = false
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const key in remoteConfig) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete remoteConfig[key]
    ch = true
  }
  return ch
}

export function remoteConfigSet (property: string, value: any, force: boolean = false): void {
  if (!force && equal(remoteConfig[property], value)) return
  remoteConfig[property] = value
  if (loaded) {
    userConfigEdit(userId, property, value).catch(e => { console.warn(e) })
  }
  changed.emit()
}

export function ws (data: Record<string, any>): void {
  loaded = true
  let ch = false
  for (const key in data) {
    if (equal(remoteConfig[key], data[key])) continue
    remoteConfig[key] = data[key]
    ch = true
  }
  if (ch) changed.emit()
}

export const onChange = changed.on.bind(changed)

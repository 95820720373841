import { ad, api, apiAbortable, apiCancelable } from '@p/app/service/backend/index.ts'
import type {
  AdDistributionAdd, AdDistributionEdit, AdDistributionMember, AdDistributionMemberAdd, DataTableAdDistributionMember, AdDistributionAlias, AdDistributionAliasAdd,
  DataTableAdDistributionRelatedId,
  TableCombined,
  TableQuery,
  AdMailAutocompleteItem,
  DataTableAdDistributionAlias
} from '@pitman/ad-api'
import type { Item } from '@pitman/core-api'
import { type Getter } from '@p/app/types/Getter.ts'

export async function add (tenantId: number, data: AdDistributionAdd): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionAdd', tenantId, data)
}

export async function edit (id: number, data: AdDistributionEdit): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionEdit', id, data)
}

export function tableMember (aliasId: number, adMailboxAliasTable: TableQuery): Getter<DataTableAdDistributionMember> {
  return apiCancelable(ad, 'adDistributionMemberTable', aliasId, adMailboxAliasTable)
}

export async function pull (id: number): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionPull', id)
}

export function table (adMailboxAliasTable: TableCombined, tenantId?: number, nodeId?: number): Getter<DataTableAdDistributionMember> {
  return apiCancelable(ad, 'adDistributionNodeTable', adMailboxAliasTable, tenantId, nodeId)
}

export async function addMember (tenantId: number, data: AdDistributionMemberAdd): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionMemberAdd', tenantId, data)
}

export async function getMember (id: number): Promise<AdDistributionMember> {
  return await api({ alert: true, task: true }, ad, 'adDistributionMemberGet', id)
}

export async function removeMember (tenantId: number): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionMemberDelete', tenantId)
}

export async function addAlias (distributionId: number, data: AdDistributionAliasAdd): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionAliasAdd', distributionId, data)
}

export async function removeAlias (aliasId: number): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionAliasDelete', aliasId)
}

export async function getAlias (aliasId: number): Promise<AdDistributionAlias> {
  return await api({ alert: true, task: false }, ad, 'adDistributionAliasGet', aliasId)
}

export function tableAlias (aliasId: number, adDistributionAliasTable: TableQuery): Getter<DataTableAdDistributionAlias> {
  return apiCancelable(ad, 'adDistributionAliasTable', aliasId, adDistributionAliasTable)
}

export async function autocomplete (signal: AbortSignal | undefined, id: number, filter: TableCombined): Promise<AdMailAutocompleteItem[]> {
  return await apiAbortable(signal, ad, 'adDistributionMemberAutocomplete', id, filter)
}

export async function sync (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDistributionSync', nodeId)
}

export function nodeAutocomplete (filter: TableCombined, tenantId?: number, nodeId?: number): Getter<Item[]> {
  return apiCancelable(ad, 'adDistributionNodeAutocomplete', filter, tenantId, nodeId)
}

export function mailboxDistributionTable (nodeId: number, mailboxId: number, filter: TableCombined): Getter<DataTableAdDistributionRelatedId> {
  return apiCancelable(ad, 'adDistributionMailboxMemberTable', nodeId, mailboxId, filter)
}

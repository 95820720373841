import { core, api } from '@p/app/service/backend/index.ts'
import type { CoreAuth, CoreAuthChangeOtpViaTokenRequest, CoreAuthChangePasswordViaTokenRequest, CoreAuthRequest, CoreAuthTokenOtpEnableRequest, CoreAuthTokenOtpEnableViaTokenRequest, CoreAuthTokenRequest, CoreAuthTotp, CoreLoginPrecheck200Response, CoreUserMigrate, RbacVirtualPermission } from '@pitman/core-api'

export async function login (data: CoreAuthRequest, plain: boolean = false): Promise<CoreAuth> {
  return await api({ alert: true, task: false }, core, 'coreAuthLogin', data, plain)
}

export async function startup (): Promise<CoreAuth> {
  return await api({ alert: false, task: false }, core, 'coreAuthStartup')
}

export async function token (data: CoreAuthTokenRequest, plain: boolean = false): Promise<CoreAuth> {
  return await api({ alert: true, task: false }, core, 'coreAuthToken', data, plain)
}

export async function logout (): Promise<void> {
  await api({ alert: true, task: false }, core, 'coreAuthLogout')
}

export async function precheck (username: string): Promise<CoreLoginPrecheck200Response | null> {
  return await api({ alert: true, task: false }, core, 'coreLoginPrecheck', username)
}

export async function migrate (userId: number, body: CoreUserMigrate): Promise<number> {
  return await api({ alert: true, task: true }, core, 'coreUserMigrateOpenID', userId, body)
}

export async function sendPasswordResetToken (username: string): Promise<void> {
  await api({ alert: true, task: false }, core, 'coreAuthSendPasswordResetToken', username)
}

export async function changePasswordViaToken (data: CoreAuthChangePasswordViaTokenRequest): Promise<void> {
  await api({ alert: true, task: false }, core, 'coreAuthChangePasswordViaToken', data)
}

export async function changeOtpViaToken (data: CoreAuthChangeOtpViaTokenRequest): Promise<CoreAuthTotp> {
  return await api({ alert: true, task: false }, core, 'coreAuthChangeOtpViaToken', data)
}

export async function sendOtpResetToken (username: string): Promise<void> {
  await api({ alert: true, task: false }, core, 'coreAuthSendOtpReset', username)
}

export async function enableTotpViaToken (data: CoreAuthTokenOtpEnableViaTokenRequest): Promise<void> {
  await api({ alert: true, task: false }, core, 'coreAuthEnableTotpViaToken', data)
}

export async function enableTotp (userId: number, data: CoreAuthTokenOtpEnableRequest): Promise<void> {
  await api({ alert: true, task: false }, core, 'coreUserEnableTotp', userId, data)
}

export async function impersonate (userId: number): Promise<CoreAuth> {
  return await api({ alert: true, task: false }, core, 'coreAuthImpersonate', userId)
}

export async function impersonateAbort (): Promise<CoreAuth> {
  return await api({ alert: true, task: false }, core, 'coreAuthImpersonateAbort')
}

export async function impersonateMissing (userId: number): Promise<RbacVirtualPermission[]> {
  return await api({ alert: true, task: false }, core, 'coreAuthImpersonateMissing', userId)
}

import type { RuleResult } from '@p/rules/types/rule'
import { type RulesBase } from '@p/rules/types/rules'
import { type ComputedRef } from 'vue'

export type FormLanguageFunc = <E extends false | true = false>(key: string, options?: Record<string, any>, wait?: E) => false extends E ? string : Promise<string>
export type FormLanguageCustomFunc = (ns: string) => FormLanguageFunc

export type FormRule<T> = RulesBase<T> | Array<FormRuleVuetify<T>>

export type FormRuleVuetify<T> = RuleResult | ((value: T) => RuleResult)

export enum FormRulesState {
  Unknown,
  Loading,
  Invalid,
  Valid
}

export interface FormField {
  reset: (resetFocused?: boolean) => void
  validate: () => Promise<boolean> | boolean
}

export interface Stepper {
  next: () => Promise<boolean> | boolean
}

export interface FormState {
  disabled: ComputedRef<boolean | undefined>
  readonly: ComputedRef<boolean | undefined>
  loading: ComputedRef<boolean>
  changed: ComputedRef<boolean>
}

export interface FormActions {
  validate: () => Promise<boolean>
  reset: () => void
  next: () => Promise<boolean>
  submit: (context?: any) => Promise<void>
}

export interface FormRegister {
  register: (field: FormField) => void
  unregister: (field: FormField) => void
}

export interface FormSubRegister {
  register: () => void
  unregister: () => void
}

export interface FormStepperRegister {
  register: (stepper: Stepper) => void
  unregister: (stepper: Stepper) => void
}

export interface FormTableQuery<V> {
  search?: string
  searchColumns?: V
  functionTags?: number[]
  page: number
  itemsPerPage: number
}

export interface FormIdsGet<T> {
  ids: Array<Exclude<T, null>>
}

export type Source<T, V, R> = (signal: AbortSignal, filter: FormTableQuery<V> | FormIdsGet<T>) => Promise<R[]>

import { api, meta } from '@p/app/service/backend/index.ts'

import { type FunctionTagsDataInput } from '@pitman/meta-api'
export async function metaTagsEdit (nodeId: number, data: number[]): Promise<number> {
  return await api({ alert: true, task: true }, meta, 'metaFunctionTagsEdit', nodeId, data)
}

export async function metaTagsGet (tenantId: number, activeModules?: number[]): Promise<FunctionTagsDataInput[]> {
  return await api({ alert: true, task: false }, meta, 'functionTags', tenantId, activeModules ?? [])
}

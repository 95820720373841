<template>
  <div class="u-icon">
    <v-icon
      :color="props.color"
      :size="props.size"
      :tag="props.tag"
      :theme="props.theme"
      :start="props.start"
      :end="props.end"
      :icon="iconData.icon"
    >
      <slot />
    </v-icon>
    <div
      v-for="(attachment, i) in iconData.attachments ?? []"
      :key="i"
      class="absolute"
      :style="getAttachmentStyle(attachment)"
    >
      <v-icon
        :size="props.size"
        :icon="attachment.icon"
        :color="attachment.color"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { type AttachedIcon, parseIconQuery } from '@p/app/helper/icon'
import { computed } from 'vue'

interface Props {
  color?: string
  end?: boolean
  icon?: string
  size?: string | number
  start?: boolean
  tag?: string
  theme?: string
}

const props = defineProps<Props>()

const iconData = computed(() => {
  return parseIconQuery(props.icon)
})

function getAttachmentStyle (attachment: AttachedIcon): { top: string, left: string, transform: string, rotate?: string, background?: string, borderRadius?: string } {
  return {
    top: (((attachment.position?.[1] ?? 0) * 100) - 50) + '%',
    left: (((attachment.position?.[0] ?? 0) * 100) - 50) + '%',
    transform: `scale(${attachment.scale ?? 1}) rotate(${attachment.rotation ?? 0}deg)`,
    background: !attachment.background ? undefined : attachment.background === true ? 'rgb(var(--v-theme-surface))' : attachment.background,
    borderRadius: attachment.background === false ? undefined : '9999px'
  }
}

</script>
<style scoped>
.u-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  letter-spacing: normal;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}
</style>

<template>
  <div class="centered-content">
    <div>
      <img :src="logo">
      <Skeletor
        style="width: 100%"
        height="20px"
        pill
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Skeletor } from 'vue-skeletor'

const logo = 'v1/theme/logo_login.png'

</script>
<style scoped>
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
</style>

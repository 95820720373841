import type { Ref } from 'vue'

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
  None = 4
}

export interface Logging {
  logLevel: Ref<LogLevel | null>
  sessionId: string
}

<template>
  <Suspense :timeout="0">
    <IndexApp />
    <template #fallback>
      <Home />
    </template>
  </Suspense>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import Home from './Home.vue'

const IndexApp = defineAsyncComponent(async () => await import('./IndexApp.vue'))
</script>

<style lang="scss">
@import 'vue-skeletor/dist/vue-skeletor.css';

* {
    padding: 0;
    margin: 0;
}

html {
  line-height: 1.5;
  overflow: hidden !important;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 60px;
    background: rgba(var(--v-theme-on-surface),0.3);
    transition: background 500ms ease-in-out;
    border: 3px solid rgb(var(--v-theme-surface));
    &:hover {
        background: rgba(var(--v-theme-primary), .4);
    }
}
</style>

import { api, apiCancelable, vmMachine } from '@p/app/service/backend/index.ts'
import type { Item } from '@pitman/core-api'
import { type Getter } from '@p/app/types/Getter.ts'
import type { ItemString, VmDatastoreGroupAvailable, VmMachineAdd, VmResourcePoolGroupAvailable, VmMachineClone, VmMachineEdit, VmMachineLink, TableCombined, DataTableVmMachine, TableQuery, DataTableVmEvent, VmMachineGuestsResponseInner, VmMachineGlobalCloneableGet200Response, VmResourceSummary, VmMachinePowerResponseInner } from '@pitman/vm-api'

export async function link (nodeId: number, data: VmMachineLink): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineLink', nodeId, data)
}
export async function add (nodeId: number, data: VmMachineAdd): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineAdd', nodeId, data)
}

export async function clone (nodeId: number, machineId: number, data: VmMachineClone, datastoreMoveAllowed: boolean = false): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineClone', nodeId, machineId, data, datastoreMoveAllowed)
}

export async function pull (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachinePull', nodeId)
}

export async function edit (nodeId: number, config: VmMachineEdit, datastoreMoveAllowed: boolean = false): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineEdit', nodeId, config, datastoreMoveAllowed)
}

export async function on (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineOn', nodeId)
}

export async function standby (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineStandby', nodeId)
}

export async function shutdown (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineShutdown', nodeId)
}

export async function off (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineOff', nodeId)
}

export async function reset (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineReset', nodeId)
}

export async function reboot (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineReboot', nodeId)
}

export async function suspend (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineSuspend', nodeId)
}

export async function unlink (nodeId: number): Promise<number> {
  return await api({ alert: true, task: true }, vmMachine, 'vmMachineUnlink', nodeId)
}

export function hasMachineConflict (nodeId: number, id: string): Getter <boolean> {
  return apiCancelable(vmMachine, 'vmMachineConflict', nodeId, { vm: id })
}

export function vmMachineAutocomplete (filter: TableCombined, tenantId?: number, nodeId?: number): Getter<Item[]> {
  return apiCancelable(vmMachine, 'vmMachineNodeAutocomplete', filter, tenantId, nodeId)
}

export function cloneAutocomplete (nodeId: number, filter: TableCombined): Getter<Item[]> {
  return apiCancelable(vmMachine, 'vmMachineGlobalCloneableAutocomplete', nodeId, filter)
}

export function datastoreGroupAutocomplete (nodeId: number, filter: TableCombined): Getter<Item[]> {
  return apiCancelable(vmMachine, 'vmMachineDatastoreGroupAutocomplete', nodeId, filter)
}

export function resourcePoolGroupAutocomplete (nodeId: number, filter: TableCombined): Getter<Item[]> {
  return apiCancelable(vmMachine, 'vmMachineResourcePoolGroupAutocomplete', nodeId, filter)
}

export function globalClonableGet (nodeId: number, vmId: number): Getter<VmMachineGlobalCloneableGet200Response> {
  return apiCancelable(vmMachine, 'vmMachineGlobalCloneableGet', nodeId, vmId)
}

export function table (adMailboxAliasTable: TableQuery, tenantId?: number, nodeId?: number): Getter<DataTableVmMachine> {
  return apiCancelable(vmMachine, 'vmMachineNodeTable', adMailboxAliasTable, tenantId, nodeId)
}

export async function summary (tenantId?: number, nodeId?: number): Promise<VmResourceSummary> {
  return await api({ alert: true, task: false }, vmMachine, 'vmMachineNodeSummary', tenantId, nodeId)
}

export async function vmMachinePower (machineIds: number[]): Promise<VmMachinePowerResponseInner[]> {
  return await api({ alert: true, task: false }, vmMachine, 'vmMachinePower', machineIds)
}

export async function vmMachineGuests (machineIds: number[], cache?: { use?: boolean, allowOutdated?: boolean }): Promise<VmMachineGuestsResponseInner[]> {
  return await api({ alert: true, task: false }, vmMachine, 'vmMachineGuests', { machineIds, cache })
}

export function vmMachineEvents (machineId: number, filter: TableQuery): Getter<DataTableVmEvent> {
  return apiCancelable(vmMachine, 'vmMachineEvents', machineId, filter)
}

export function vmListEvents (filter: TableQuery): Getter<DataTableVmEvent> {
  return apiCancelable(vmMachine, 'vmListEvents', filter)
}

export async function guestOs (nodeId: number): Promise<ItemString[]> {
  return await api({ alert: true, task: false }, vmMachine, 'vmMachineGuestOs', nodeId)
}

export async function resourcePoolGroupAvailable (nodeId: number, groupId: number): Promise<VmResourcePoolGroupAvailable> {
  return await api({ alert: true, task: false }, vmMachine, 'vmMachineResourcePoolGroupAvailable', nodeId, groupId)
}

export async function datastoreGroupAvailable (nodeId: number, groupId: number): Promise<VmDatastoreGroupAvailable> {
  return await api({ alert: true, task: false }, vmMachine, 'vmMachineDatastoreGroupAvailable', nodeId, groupId)
}

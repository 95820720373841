import { arrayify } from './array'

export interface IconDrawConfig {
  icon: string
  attachments: AttachedIcon[]
}

export interface AttachedIcon {
  icon: string
  scale?: number
  position?: [number, number]
  color?: string
  rotation?: number
  background?: boolean | string
}

export function parseIconQuery (icon?: string): IconDrawConfig {
  if ((icon?.length ?? 0) <= 0) return { icon: '', attachments: [] }
  if (icon?.[0] === '{') {
    return JSON.parse(icon) as IconDrawConfig
  } else {
    return {
      icon: icon!,
      attachments: []
    }
  }
}

export function addIconAttachment (icon: string | IconDrawConfig, attachment: AttachedIcon | AttachedIcon[]): IconDrawConfig {
  let iconConfig!: IconDrawConfig

  if (typeof icon === 'string') {
    iconConfig = parseIconQuery(icon)
  } else {
    iconConfig = icon
  }

  const arr = arrayify(attachment)
  iconConfig.attachments.push(...arr)
  return iconConfig
}

export function setIconAttachment (icon: string | IconDrawConfig, attachment: AttachedIcon | AttachedIcon[]): string {
  let iconConfig!: IconDrawConfig

  if (typeof icon === 'string') {
    iconConfig = parseIconQuery(icon)
  } else {
    iconConfig = icon
  }

  const arr = arrayify(attachment)
  iconConfig.attachments = arr
  return getIconQueryString(iconConfig)
}

export function getIconQueryString (config: IconDrawConfig): string {
  return JSON.stringify(config)
}

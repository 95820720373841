import { pbx, api, apiCancelable, apiAbortable } from '@p/app/service/backend/index.ts'
import type { TableCombined, DataTablePbxUser, PbxForwardingAdd, PbxForwardingBasic, PbxForwardingCheck, PbxForwardingEdit, PbxLicensesAdd, PbxUserAdd, PbxUserEdit, PbxUserFakeNumberEdit, PbxUserFilter, TableQuery, PbxUserUpdateUserGroupStatusStatusEnum, PbxFunctionKey } from '@pitman/pbx-api'
import type { Item } from '@pitman/directory-api'
import { type Getter } from '@p/app/types/Getter.ts'

export async function add (parentId: number, data: PbxUserAdd): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxUserAdd', parentId, data)
}

export async function edit (userId: number, data: PbxUserEdit, oldName?: string): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxUserEdit', userId, data, oldName)
}

export async function pull (userId: number): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxUserPull', userId)
}

export async function freeNumbers (nodeId: number): Promise<string[]> {
  return await api({ alert: true, task: false }, pbx, 'pbxUserFreeNumbers', nodeId)
}

export function pbxUserFreeKeyNumbers (userId: number): Getter<PbxFunctionKey[]> {
  return apiCancelable(pbx, 'pbxUserFreeKeyNumbers', userId)
}

export async function pbxUserFreeKeyNumbersAbortable (signal: AbortSignal, userId: number): Promise<PbxFunctionKey[]> {
  return await apiAbortable(signal, pbx, 'pbxUserFreeKeyNumbers', userId)
}

export async function allFilters (userId: number): Promise<{ filters: string[], cdFilters: string[] }> {
  return await api({ alert: true, task: false }, pbx, 'pbxUserAllFilters', userId)
}

export function pbxUserNodeAutocomplete (pbxUserTableIdsGet: TableCombined, tenantId?: number, nodeId?: number, filter?: PbxUserFilter): Getter<Item[]> {
  return apiCancelable(pbx, 'pbxUserNodeAutocomplete', pbxUserTableIdsGet, tenantId, nodeId, filter)
}

export async function pbxUserNodeAutocompleteAbortable (signal: AbortSignal, pbxUserTableIdsGet: TableCombined, tenantId?: number, nodeId?: number, filter?: PbxUserFilter): Promise<Item[]> {
  return await apiAbortable(signal, pbx, 'pbxUserNodeAutocomplete', pbxUserTableIdsGet, tenantId, nodeId, filter)
}

export async function pbxUserNodeAutocompleteVoiceMailAbortable (signal: AbortSignal, pbxUserTableIdsGet: TableCombined, tenantId: number): Promise<Item[]> {
  return await apiAbortable(signal, pbx, 'pbxUserNodeAutocompleteVoiceMail', tenantId, pbxUserTableIdsGet)
}

export function pbxUserTable (filter: TableCombined, tenantId?: number, nodeId?: number): Getter<DataTablePbxUser> {
  return apiCancelable(pbx, 'pbxUserNodeTable', filter, tenantId, nodeId)
}

export function pbxUserForwardingTable (tenantId: number, filter: TableQuery): Getter<{ items: any, total: number }> {
  return apiCancelable(pbx, 'pbxUserForwardingTable', tenantId, filter)
}

export async function pbxUserForwardingCheck (parentId: number, data: PbxForwardingBasic, forwardingId: number): Promise<PbxForwardingCheck> {
  return await api({ alert: true, task: false }, pbx, 'pbxUserForwardingCheck', parentId, data, forwardingId)
}

export async function emailConflict (parentId: number, email: string): Promise<boolean> {
  return await api({ alert: true, task: false }, pbx, 'pbxUserEmailConflict', parentId, email)
}

export async function pbxUserForwardingAdd (parentId: number, data: PbxForwardingAdd): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxUserForwardingAdd', parentId, data)
}

export async function pbxUserForwardingEdit (userId: number, data: PbxForwardingEdit): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxUserForwardingEdit', userId, data)
}

export async function pbxUserForwardingRemove (userId: number): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxUserForwardingRemove', userId)
}

export async function alternative (userId: number, body: PbxUserFakeNumberEdit): Promise<number> {
  return await api({ alert: true, task: false }, pbx, 'pbxUserAlternativeEdit', userId, body)
}

export async function checkIfUcCheaper (entityId: number, body: PbxLicensesAdd): Promise<boolean> {
  return await api({ alert: true, task: false }, pbx, 'pbxUserCheckIfUcCheaper', entityId, body)
}

export async function pbxUserUpdateGroupStatus (userId: number, groupId: number, status: PbxUserUpdateUserGroupStatusStatusEnum): Promise<void> {
  await api({ alert: true, task: false }, pbx, 'pbxUserUpdateUserGroupStatus', userId, groupId, status)
}

export async function pbxUserEditFunctionKeys (userId: number, functionKeys: PbxFunctionKey[]): Promise<void> {
  await api({ alert: true, task: true }, pbx, 'pbxUserEditFunctionKeys', userId, functionKeys)
}

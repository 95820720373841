if (AbortSignal.prototype.throwIfAborted === undefined) {
  AbortSignal.prototype.throwIfAborted = function () {
    if (this.aborted) throw this.reason ?? new DOMException('polyfill', 'AbortError')
  }
}

export { Entity } from './classes/Entity.ts'
export { EntityLink } from './classes/EntityLink.ts'
export { EntityStore } from './classes/EntityStore.ts'
export { retrieveCollector } from './helper/retrieveCollector.ts'

export type { HttpError } from './types/helper.ts'

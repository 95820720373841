import type { OptionalArgTuple, Callback } from '@p/app/types/Helper.ts'

export class EventOn<T = undefined> {
  private readonly cbs: Array<Callback<T>> = []

  on (callback: Callback<T>): void {
    this.cbs.push(callback)
  }

  off (callback: Callback<T>): boolean {
    const index = this.cbs.indexOf(callback)
    if (index === -1) return false
    this.cbs.splice(index, 1)
    return true
  }

  emit (...params: OptionalArgTuple<T>): void {
    for (let i = 0; i < this.cbs.length; i++) {
      this.cbs[i](...params)
    }
  }
}

import { billing, api, apiCancelable } from '@p/app/service/backend/index.ts'
import type { BillingFreebieAdd, ItemString, DataTableBillingFreebie, TableQuery, TableCombinedString } from '@pitman/billing-api'
import { type Getter } from '@p/app/types/Getter.ts'

export function billingFreebieTable (tenantId: number, body: TableQuery): Getter<DataTableBillingFreebie> {
  return apiCancelable(billing, 'billingFreebieTable', tenantId, body)
}

export async function billingFreebieAdd (tenantId: number, body: BillingFreebieAdd): Promise<number> {
  return await api({ alert: true, task: true }, billing, 'billingFreebieAdd', tenantId, body)
}

export async function billingFreebieEdit (freebieId: number, body: BillingFreebieAdd): Promise<number> {
  return await api({ alert: true, task: true }, billing, 'billingFreebieEdit', freebieId, body)
}

export async function billingFreebieRemove (freebieId: number): Promise<number> {
  return await api({ alert: true, task: true }, billing, 'billingFreebieRemove', freebieId)
}

export function billingFreebieArticles (freebieId: number, body: TableCombinedString): Getter<ItemString[]> {
  return apiCancelable(billing, 'billingArticleFreebieArticles', freebieId, body)
}

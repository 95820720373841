import { type HttpError } from '../types/helper.ts'

export class HttpException extends Error implements HttpError {
  status = 404
  errors = []

  constructor () {
    super('uicache - 404 error')
  }
}

import type { State } from '@p/app/types/State.ts'
import { AppView } from '@pitman/core-api'
import { reactive } from 'vue'

export const defaults: State = {
  authenticated: false,
  id: 0,
  tenant: 0,
  requirePasswordChange: false,
  impersonated: false,
  startView: AppView.TreeView,
  globalWarning: { expiresAt: null, langKey: null },
  showModifiedWarning: false
}

export const state = reactive(Object.assign({}, defaults))

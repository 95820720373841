import { pbx, api, apiCancelable, apiAbortable } from '@p/app/service/backend/index.ts'
import type { TableCombined, PbxGroupAdd, PbxGroupEdit, TableQuery, PbxForwardingBasic, PbxForwardingCheck, PbxForwardingAdd, PbxForwardingEdit, DataTablePbxGroup, Item, UserInGroupStatus } from '@pitman/pbx-api'
import { type Getter } from '@p/app/types/Getter.ts'

// export async function forwardingConditionCheckName (name: string, tenantId: number, nodeId: number): Promise<boolean> {
//   return await api({ alert: true, task: false }, pbx, 'pbxGr', tenantId, name, nodeId)
// }

// export function forwardingConditionAutocomplete (table: TableCombined, tenantId?: number, nodeId?: number): Getter<Item[]> {
//   return apiCancelable(pbx, 'pbxForwardingConditionAutocomplete', table, tenantId, nodeId)
// }

export function groupTable (table: TableCombined, tenantId?: number, nodeId?: number): Getter<DataTablePbxGroup> {
  return apiCancelable(pbx, 'pbxGroupTable', table, tenantId, nodeId)
}
export async function groupAdd (nodeId: number, data: PbxGroupAdd): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxGroupAdd', nodeId, data)
}

export async function groupEdit (groupId: number, data: PbxGroupEdit): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxGroupEdit', groupId, data)
}

export function groupAutocomplete (table: TableCombined, tenantId?: number, nodeId?: number): Getter<Item[]> {
  return apiCancelable(pbx, 'pbxGroupAutocomplete', table, tenantId, nodeId)
}

export async function pbxGroupAutocomplete (signal: AbortSignal, table: TableCombined, tenantId?: number, nodeId?: number): Promise<Item[]> {
  return await apiAbortable(signal, pbx, 'pbxGroupAutocomplete', table, tenantId, nodeId)
}

export async function pbxGroupAutocompleteVoiceMail (signal: AbortSignal, table: TableCombined, tenantId: number): Promise<Item[]> {
  return await apiAbortable(signal, pbx, 'pbxGroupAutocompleteVoiceMail', tenantId, table)
}

export async function groupfreeNumbers (nodeId: number): Promise<string[]> {
  return await api({ alert: true, task: false }, pbx, 'pbxGroupFreeNumbers', nodeId)
}

export function pbxGroupForwardingTable (tenantId: number, filter: TableQuery): Getter<{ items: any, total: number }> {
  return apiCancelable(pbx, 'pbxGroupForwardingTable', tenantId, filter)
}

export async function pbxGroupForwardingCheck (parentId: number, data: PbxForwardingBasic, forwardingId: number): Promise<PbxForwardingCheck> {
  return await api({ alert: true, task: false }, pbx, 'pbxGroupForwardingCheck', parentId, data, forwardingId)
}

export async function pbxGroupForwardingAdd (parentId: number, data: PbxForwardingAdd): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxGroupForwardingAdd', parentId, data)
}

export async function pbxGroupForwardingEdit (GroupId: number, data: PbxForwardingEdit): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxGroupForwardingEdit', GroupId, data)
}

export async function pbxGroupForwardingRemove (GroupId: number): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxGroupForwardingRemove', GroupId)
}

export async function pbxGroupUsersInGroupStatus (groupId: number): Promise<UserInGroupStatus[]> {
  return await api({ alert: true, task: false }, pbx, 'pbxGroupUsersInGroupStatus', groupId)
}

export enum ChangeState {
  UNCHANGED,
  CHANGED,
  NEW,
  REMOVED,
  MOVED,
  MOVED_FROM,
  MOVED_TO
}

export interface CompareData {
  id: number
  altId?: number
  name: string
  state: ChangeState
  icon?: string
  children?: CompareData[]
}

import $alert from '@p/app/plugins/alert.ts'
import type { HttpError } from '@p/uicache'

const IGNORED_ERROR_CODES = [
  154, // Totp Invalid
  153, // Totp required
  155, // Require OTP Reset
  14 // TaskAfter
]

export function errorAlert (e?: HttpError): void {
  if (e?.errors == null) {
    $alert.error('app:error.unknown')
  } else if (e.errors.length === 0) {
    $alert.error('app:error.status.' + e.status.toString())
  } else {
    for (const error of e.errors) {
      let context = error.context
      if (context !== undefined && typeof context !== 'object') {
        context = { data: context }
      }
      if (!IGNORED_ERROR_CODES.includes(error.code)) $alert.error('app:error.code.' + error.code.toString(), context as Record<string, string> | undefined)
    }
  }
}

import { ad, api, apiCancelable } from '@p/app/service/backend/index.ts'
import type { AdDomain, AdDomainAdd, AdDomainConflict, AdDomainEdit, AdDomainExistsRequest, AdSpamConflict, AdSpamRulesAdd, DataTableAdDomain, DataTableAdMessageLog, DataTableAdSpamRules, TableCombined, TableQuery } from '@pitman/ad-api'
import type { Item } from '@pitman/core-api'
import { type Getter } from '@p/app/types/Getter.ts'

export async function add (tenantId: number, data: AdDomainAdd): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDomainAdd', tenantId, data)
}

export async function edit (id: number, data: AdDomainEdit): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDomainEdit', id, data)
}

export async function getByTenant (id: number): Promise<AdDomain[]> {
  return await api({ alert: true, task: true }, ad, 'adDomainGetByTenant', id)
}

export function table (adMailboxAliasTable: TableCombined, tenantId?: number, nodeId?: number): Getter<DataTableAdDomain> {
  return apiCancelable(ad, 'adDomainNodeTable', adMailboxAliasTable, tenantId, nodeId)
}

export function nodeAutocomplete (filter: TableCombined, tenantId?: number, nodeId?: number): Getter<Item[]> {
  return apiCancelable(ad, 'adDomainNodeAutocomplete', filter, tenantId, nodeId)
}

export function txt (nodeId: number, check: AdDomainConflict): Getter<boolean> {
  return apiCancelable(ad, 'adDomainTxt', nodeId, check)
}

export function mx (nodeId: number, check: AdDomainConflict): Getter<boolean> {
  return apiCancelable(ad, 'adDomainMx', nodeId, check)
}

export function spf (nodeId: number, check: AdDomainConflict): Getter<boolean> {
  return apiCancelable(ad, 'adDomainSpf', nodeId, check)
}

export function discovery (nodeId: number, check: AdDomainConflict): Getter<boolean> {
  return apiCancelable(ad, 'adDomainDiscovery', nodeId, check)
}

export function conflict (nodeId: number, check: AdDomainConflict): Getter<boolean> {
  return apiCancelable(ad, 'adDomainConflict', nodeId, check)
}

export function spamTable (id: number, table: TableQuery): Getter<DataTableAdSpamRules> {
  return apiCancelable(ad, 'adSpamRulesDomainTable', id, table)
}

export async function spamConflict (domainId: number, body: AdSpamConflict): Promise<boolean> {
  return await api({ alert: true, task: false }, ad, 'adSpamRulesDomainConflict', domainId, body)
}

export async function spamAdd (domainId: number, body: AdSpamRulesAdd): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adSpamRulesDomainAdd', domainId, body)
}

export async function spamRemove (domainId: number): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adSpamRulesDomainDelete', domainId)
}
export function messageLogTable (domainId: number, table: TableQuery): Getter<DataTableAdMessageLog> {
  return apiCancelable(ad, 'adMessageLogDomainTable', domainId, table)
}

export async function exists (nodeId: number, mail: AdDomainExistsRequest): Promise<boolean> {
  return await api({ alert: true, task: false }, ad, 'adDomainExists', nodeId, mail)
}

export async function existsMulti (nodeId: number, mails: AdDomainExistsRequest[]): Promise<boolean[]> {
  return await api({ alert: true, task: false }, ad, 'adDomainsExists', nodeId, mails)
}

export async function overwriteEsa (domainId: number): Promise<number> {
  return await api({ alert: true, task: true }, ad, 'adDomainEsa', domainId)
}

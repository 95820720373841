import { type Getter } from '@p/app/types/Getter.ts'

export function unwrapPromise<T> (): [
  Promise<T>,
  (value: T | PromiseLike<T>) => void,
  (reason?: any) => void
] {
  let _resolve: (value: T | PromiseLike<T>) => void
  let _reject: (reason?: any) => void
  const promise: Promise<T> = new Promise<T>((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })
  // @ts-expect-error: _resolve, _rejec are really assigned before being used
  return [promise, _resolve, _reject]
}

export function getterThen<T, U = T> (getter: Getter<T>, process: (value: T) => U | Promise<U>): Getter<U> {
  return {
    cancel: getter.cancel,
    data: getter.data.then(async e => {
      if (e === undefined) return undefined
      return await process(e)
    })
  }
}

export function getterThenMore<T, U = T> (getter: Getter<T>, process: (value: T) => Getter<U> | Promise<U>): Getter<U> {
  let inner: Getter<U> | undefined
  return {
    cancel: () => {
      getter.cancel()
      inner?.cancel()
    },
    data: getter.data.then(async e => {
      if (e === undefined) return undefined
      const r = process(e)
      if (r instanceof Promise) return await r
      inner = r
      return await r.data
    })
  }
}

export function getterMerge<T> (getters: Array<Getter<T>>): Getter<T[]> {
  return {
    cancel: () => { getters.forEach(x => { x.cancel() }) },
    data: Promise.all(getters.map(async x => await x.data)).then(e => {
      if (e.some(e => e === undefined)) return undefined
      return e as T[]
    })
  }
}

export function getterMergeFlat<T> (getters: Array<Getter<T[]>>): Getter<T[]> {
  const merged = getterMerge(getters)
  return getterThen(merged, e => e.flat())
}

// taken from https://stackoverflow.com/a/53508547
export async function mapAsync<T, U> (array: T[], callbackfn: (value: T, index: number, array: T[]) => Promise<U>): Promise<U[]> {
  return await Promise.all(array.map(callbackfn))
}

export async function filterAsync<T> (array: T[], callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>): Promise<T[]> {
  const filterMap = await mapAsync(array, callbackfn)
  return array.filter((value, index) => filterMap[index])
}

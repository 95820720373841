import type { AlertOptions, ProgressAlert as ProgressAlertInstance, Alert as AlertInstance, AlertObj } from '@p/app/types/Alert.ts'
import { AlertType, AlertCustom } from '@p/app/types/Alert.ts'
import equal from 'fast-deep-equal'

import { reactive } from 'vue'
import { mdiInformation, mdiAlert, mdiCheck, mdiRobotDead, mdiClipboardArrowDown } from '@mdi/js'

let counter: number = 0

const alertObj: AlertObj = {
  default: (text, params, timeout) => alert(AlertCustom.Default, AlertType.Default, text, params, timeout),
  warning: (text, params, timeout) => alert(AlertCustom.Default, AlertType.Warning, text, params, timeout),
  success: (text, params, timeout) => alert(AlertCustom.Default, AlertType.Success, text, params, timeout),
  error: (text, params, timeout) => alert(AlertCustom.Default, AlertType.Error, text, params, timeout),
  clipboard: (text, params, timeout) => alert(AlertCustom.Default, AlertType.Clipboard, text, params, timeout),
  progress: (text, params, timeout) => alert<ProgressAlert>(AlertCustom.Progress, AlertType.Progress, text, params, timeout),
  arr: []
}
const $alert = reactive(alertObj)

function alert<T extends Alert = Alert> (custom: AlertCustom, type: AlertType, text: string, params: Record<string, string> = {}, timeout?: number): T {
  let alertInstance: Alert
  const lastEntry = $alert.arr[$alert.arr.length - 1] as AlertInstance

  if (lastEntry) {
    if (lastEntry.active && lastEntry.type === type && lastEntry.custom === custom && lastEntry.text === text && equal(lastEntry.params, params)) {
      if (!lastEntry.amount) lastEntry.amount = 2
      else lastEntry.amount++

      lastEntry.date = new Date().getTime()
      if (timeout) lastEntry.timeout = timeout
      else if (lastEntry.timeout > 0) { lastEntry.timeout++ }

      return lastEntry as T
    }
  }

  if (custom === AlertCustom.Progress) {
    alertInstance = new ProgressAlert(counter++, type, text, params, timeout)
    alertInstance.custom = custom
  } else {
    alertInstance = new Alert(counter++, type, text, params, AlertCustom.Default, timeout)
    alertInstance.custom = custom
  }

  $alert.arr.splice(counter, 1, (alertInstance))

  return alertInstance as T
}

export default $alert

const options: Record<AlertType, AlertOptions> = {
  [AlertType.Default]: { icon: mdiInformation, color: 'primary' },
  [AlertType.Warning]: { icon: mdiAlert, color: 'orange' },
  [AlertType.Success]: { icon: mdiCheck, color: 'green' },
  [AlertType.Error]: { icon: mdiRobotDead, color: 'red' },
  [AlertType.Clipboard]: { icon: mdiClipboardArrowDown, color: 'primary' },
  [AlertType.Progress]: { icon: '', color: 'default' }
}

class Alert implements AlertInstance {
  date: number
  active: boolean
  infinite: boolean = false
  canClose = true
  amount: number = 1

  constructor (public id: number, public type: AlertType, public text: string, public params: Record<string, string> = {}, public custom: AlertCustom = AlertCustom.Default, public timeout: number = 5000) {
    this.date = Date.now()
    this.active = this.timeout > 0
    this.init()
  }

  init (): void {

  }

  get options (): AlertOptions {
    return options[this.type]
  }
}

class ProgressAlert extends Alert implements ProgressAlertInstance {
  progress: number = 0
  progressMax: number = 0
  currentMessage = ''

  init (): void {
    this.infinite = true
  }
}

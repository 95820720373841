export function chunk<T> (array: T[], size: number): T[][] {
  const chunkedArr: T[][] = []
  let index: number = 0
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArr
}

export function arrayify<T> (obj: T | T[]): T[] {
  if (Array.isArray(obj)) return obj
  return [obj]
}

export function unique<T> (array: T[]): T[] {
  return array.filter((e, i) => array.indexOf(e) === i)
}

export function remove<T> (arrayToRemoveFrom: T[], elementsToRemove: T[]): T[] {
  return arrayToRemoveFrom.filter(item => !elementsToRemove.includes(item))
}

export function containsAllElements<T> (mainArray: T[], secondArray: T[]): boolean {
  // returns true if all elements of secondArray are in mainArray
  return secondArray.every(elem => mainArray.includes(elem))
}

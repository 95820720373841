import { type ComputedRef, type Raw, type WatchSource, computed, onUnmounted } from 'vue'

import { Entity } from './Entity.ts'
import type { EntityAdapter } from '../types/helper.ts'

export class EntityStore<T, U = T> {
  readonly #t: EntityAdapter<T, U>
  readonly #cache = new Map<number, Raw<Entity<T, U>>>()

  constructor (type: EntityAdapter<T, U>) {
    const destroy = type.destroy
    type.destroy = (id, data) => {
      this.#cache.delete(id)
      destroy?.(id, data)
    }

    this.#t = type
  }

  default (): U {
    return this.#t?.process?.(0, this.#t.default, undefined, []) ?? (this.#t.default as any)
  }

  invalidate (id?: number, reason?: number): void {
    if (id === undefined) {
      for (const ele of this.#cache) {
        ele[1].invalidate(reason)
      }
    } else {
      const res = this.#cache.get(id)
      if (res === undefined) return
      res.invalidate(reason)
    }
  }

  isCached (id: number): boolean {
    return this.#cache.has(id)
  }

  get (id: number = 0): Raw<Entity<T, U>> {
    return this.#getCache(id).aquire()
  }

  async getAsync (id: number = 0): Promise<Raw<Entity<T, U>>> {
    return await this.#getCache(id).aquire().loaded()
  }

  async getData (id: number = 0): Promise<U> {
    const data = await this.#getCache(id).aquire().loaded()
    data.release()
    return data.data
  }

  map (id: number, data: T, reason?: number[]): Raw<Entity<T, U>> {
    let res = this.#cache.get(id)
    if (res === undefined) res = this.#createCache(id, data)
    else res.map(data, reason)
    return res.aquire()
  }

  #getCache (id: number): Raw<Entity<T, U>> {
    const entity = this.#cache.get(id)
    if (entity === undefined) return this.#createCache(id)
    return entity
  }

  #createCache (id: number, data?: T): Raw<Entity<T, U>> {
    const cache = Entity.factory(this.#t, id, data)
    if (id > 0) this.#cache.set(id, cache)
    return cache
  }

  /**
   * Needs to be called inside vue context (onUnmounted).
   * @param id
   */
  use (id: WatchSource<number>, useUnmounted = true): ComputedRef<Raw<Entity<T, U>>> {
    let knotRef = this.get()

    if (useUnmounted) {
      onUnmounted(() => {
        knotRef.release()
      })
    }

    return computed(() => {
      const idValue = typeof id === 'function' ? id() : id.value
      if (knotRef.id !== idValue) {
        knotRef.release()

        knotRef = idValue ? this.get(idValue) : this.get()
        // knotRef = this.get(idValue)
      }
      return knotRef
    })
  }
}

import { remoteConfig, remoteConfigSet } from '@p/app/service/remoteConfig.ts'
import type { ComputedRef, Ref, WatchSource, WritableComputedRef } from 'vue'
import { isRef, computed } from 'vue'

export function getWatchSourceValue<T> (value: WatchSource<T>): T {
  if (isRef(value)) return value.value
  return value()
}

export function useRemoteConfig<T> (property: string, initialValue: T): WritableComputedRef<T> {
  return computed({
    get () {
      return remoteConfig[property] ?? initialValue
    },
    set (value) {
      if ((remoteConfig[property] ?? initialValue) === value) return
      remoteConfigSet(property, value)
    }
  })
}

/**
 * Used to fix typing of actually unwrapped refs (useArrayEnvironment is reactive, if it contains  refs, they are unwrapped but the type needs to be fixed)
 */
export function unrefType<T> (value: ComputedRef<T> | Ref<T>): T {
  return value as unknown as T
}

import type { CoreTaskData } from '@p/app/types/CoreTaskData.ts'
import { CoreTaskStateEnum } from '@pitman/core-api'
import { shallowReactive } from 'vue'

export const taskFinishedStates = [CoreTaskStateEnum.Finished, CoreTaskStateEnum.Cancelled, CoreTaskStateEnum.Failed]
export const taskUnfinishedStates = [CoreTaskStateEnum.Queued, CoreTaskStateEnum.Continue, CoreTaskStateEnum.Running, CoreTaskStateEnum.Waiting]
export const taskPendingStates = [CoreTaskStateEnum.Running, CoreTaskStateEnum.Waiting, CoreTaskStateEnum.Continue]
export const taskReadyStates = [CoreTaskStateEnum.Queued, CoreTaskStateEnum.Continue]

export const tasksMap = new Map<number, CoreTaskData>()
export const tasksHere = new Set<number>()
export const tasks: CoreTaskData[] = shallowReactive([])

import { type GlobalWarning } from '@pitman/lang-api'

export interface State {
  authenticated: boolean
  id: number
  tenant: number
  requirePasswordChange: boolean
  impersonated: boolean
  startView: number
  globalWarning: GlobalWarning
  showModifiedWarning: boolean
}

export enum AuthenticationType {
  Legacy,
  Plain,
  OpenID
}

export interface Authentication {
  type: AuthenticationType
  access: null | string
  refresh: null | string
  userId: null | string
}
